<template>
  <div>
    <CRow v-if="platformRolesLoaded && platformPermissionsLoaded && checkPermission('loyalty.moments.templates')" class="events">
      <CCol cols="12" lg="12" md="12">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
                <span>{{ $t('sm.Spark_templates') }}</span>
              </CCol>
              <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
                <div class="mt-1 mt-xl-0 d-flex align-items-start justify-content-xl-end">
                  <CButton v-if="checkPermission('loyalty.moments.newtemplate')" color="primary" class="ml-0 mr-2 admin" @click="openSidebarRight('loyalty_moment_template_details', { loyalty_moment_id_external: null, product_tag:  'loyalty'})">
                    <span><i class="fas fa-plus mr-1"/>{{$t('sm.New_spark_template')}}</span>
                  </CButton>
                  <CButton v-if="checkPermission('loyalty.moments.addcustomtemplate')" 
                           color="primary" 
                           class="m-0 orange"
                           @click="openSidebarRight('loyalty_moment_custom_template_details', { template_data: null, product_tag:  'loyalty' })">
                    <i class="fas fa-plus mr-1"/>
                    <span>{{$t('sm.New_custom_spark_template')}}</span>
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody class=" p-0">
            <div v-if="momentTemplatesLoading === true">
              <CRow >
                <CCol cols="12" lg="12">
                  <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
                </CCol>
              </CRow>          
            </div>
            <div v-else>
              <div v-if="momentTemplateLanguages.length > 0">
                <CRow class="m-0">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <div class="d-flex">
                      <div class="mt-2 mr-2 d-flex align-items-center" style="height:30px;">
                        <span class="d-flex align-items-center"><i class="fas fa-tags mr-1"/>{{$t('common.Language')}}</span>
                      </div>
                      <div>
                        <div class="filter_tags" v-bind:class="{'show_more': showAllMomentTemplateLanguages}">
                          <b-tag v-for="language in momentTemplateLanguages" 
                                v-bind:key="language.language_code"
                                @click.native="setSelectedMomentTemplateLanguages(language.language_name)"
                                class="pointer"
                                v-bind:class="{'selected' : momentFilters.moment_languages.includes(language.language_name)}">
                            <span>{{ language.language_name }} ({{ language.moments_with_language }})</span>
                          </b-tag>
                        </div>
                        <div v-if="momentTemplateLanguages.length > 20" class="mt-2">
                          <span class="pointer" @click="toggleMomentTemplateLanguages()">{{!showAllMomentTemplateLanguages ? $t('communications.Show_more_tags') : $t('communications.Show_less_tags')}}</span>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </div>
              <div v-if="momentTemplateTags.length > 0">
                <CRow class="m-0">
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <div class="d-flex">
                      <div class="mt-2 mr-2 d-flex align-items-center" style="height:30px;">
                        <span class="d-flex align-items-center"><i class="fas fa-tags mr-1"/>{{$t('common.Filter')}}</span>
                      </div>
                      <div>
                        <div class="filter_tags" v-bind:class="{'show_more': showAllMomentTemplateTags}">
                          <b-tag v-for="tag in momentTemplateTags" 
                                v-bind:key="tag.moment_tag"
                                @click.native="setSelectedMomentTemplateTags(tag.moment_tag)"
                                class="pointer"
                                v-bind:class="{'selected' : momentFilters.moment_tags.includes(tag.moment_tag)}">
                            {{ tag.moment_tag }} ({{ tag.moments_with_tag }})
                          </b-tag>
                        </div>
                        <div v-if="momentTemplateTags.length > 20" class="mt-2">
                          <span class="pointer" @click="toggleMomentTemplateTags()">{{!showAllMomentTemplateTags ? $t('communications.Show_more_tags') : $t('communications.Show_less_tags')}}</span>
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </div>
              <div v-if="moments.length > 0">
                <CRow class="m-0 moment_catalog">
                  <CCol v-for="moment in currentPageMoments[currentMomentsPage - 1]" :key="moment.loyalty_moment_template_id_external" cols="3" xl="3" lg="3" md="4" class="p-0">
                    <loyaltyMomentTemplateCard :momentData="moment" 
                                               :showEditButton="checkPermission('loyalty.moments.edittemplate') || (checkPermission('loyalty.moments.editcustomtemplate') && moment.custom_moment)" 
                                               :showMomentTag="true" 
                                               parent="overview">
                    </loyaltyMomentTemplateCard>
                  </CCol>
                </CRow>
                <CRow v-if="moments.length > momentsPerPage" class="m-0">
                  <CCol cols="12" md="12" class="pt-0">
                    <v-pagination class="justify-content-end" v-model="currentMomentsPage" :length="momentPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <CRow class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('sm.No_spark_templates_available')}}</span>
                  </CCol>
                </CRow>
              </div>              
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
  </div>
</template>

<script>
import axios from 'axios'

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import loyaltyMomentTemplateCard from '@/components/loyalty/loyaltyMomentTemplateCard.vue';

export default {
  name: 'MomentTemplates',
  components: {
    loadingSpinner,
    noPermission,
    loyaltyMomentTemplateCard
  },
  data() {
    return {
      defaultCompanyLanguage: null,
      modules: {
        loyalty_moments: 0
      },
      platformRoles: [],
      platformRolesLoaded: false,
      platformPermissions: [],
      platformPermissionsLoaded: false,
      apiBaseUrl: null,
      clientToken: null,
      moments: [],
      momentTemplatesLoading: false,
      momentFilters: {
        search_term: '',
        moment_tags: [],
        moment_languages: [],
      },      
      momentTemplateTags: [],
      momentTemplateTagsLoaded: false,
      showAllMomentTemplateTags: false,
      momentTemplateLanguages: [],
      momentTemplateLanguagesLoaded: false,
      showAllMomentTemplateLanguages: false,
      currentMomentsPage: 1,
      currentPageMoments: {},
      momentsPerPage: 12,
      momentPages: 0,      
    }
  },
  methods: {
    getMomentTemplates(showLoader) {
      let params = {};
      params.momentFilters = this.momentFilters;
      params.include_default_templates = false;
      params.include_custom_design = false;
      params.active_only = false;
      params.randomize_templates = false;
      // Start the loader
      if(showLoader) this.momentTemplatesLoading = true;
      // Get the Moment templates
      axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates', params)
      .then(res => {
        this.moments = res.data.data;
        // Set the pagination to the first page
        this.currentMomentsPage = 1;        
        // Reset the momentPages value
        this.momentPages = 0;        
        // Loop through the Moment templates to set the pagination
        for (let i = 0; i < this.moments.length; i = i + this.momentsPerPage) {
          this.currentPageMoments[this.momentPages] = this.moments.slice(i, i + this.momentsPerPage);
          this.momentPages++;
        }
        // Set the available tags
        this.setMomentTemplateTags();
        // Set the available languages
        this.setMomentTemplateLanguages();       
        // Stop the loader
        this.momentTemplatesLoading = false;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    setMomentTemplateTags() {
      if(this.momentTemplateTagsLoaded === false) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates/tags')
        .then(res => {
          this.momentTemplateTags = res.data.data;
          // Update the momentTemplateTagsLoaded value
          this.momentTemplateTagsLoaded = true;        
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Create array for momentTemplateTags
        let momentTemplateTags = [];
        // Loop through the Moment templates
        for(var g = 0; g < this.moments.length; g++) {
          momentTemplateTags = momentTemplateTags.concat(this.moments[g].loyalty_moment_tags);
        }
        // Create momentTemplateTagsCount array with counts of remaining tags
        var momentTemplateTagsCount = momentTemplateTags.reduce((tagCount, tag) => {
          if(tagCount.hasOwnProperty(tag) === false) tagCount[tag] = 0;
          tagCount[tag]++;
          return tagCount;
        }, {});
        
        // Update the momentTemplateTags array
        this.momentTemplateTags = Object.keys(momentTemplateTagsCount).map(tag => {
          return {moment_tag: tag, moments_with_tag: momentTemplateTagsCount[tag]}; 
        });
        // Sort the momentTemplateTags
        this.momentTemplateTags.sort((a,b)=> (b.moments_with_tag - a.moments_with_tag || a.moment_tag.localeCompare(b.moment_tag)));
      }
    },
    setSelectedMomentTemplateTags(tag) {
      // Get the index of the Moment template tag in the moment_tags array of the momentFilters
      let momentTagIndex = this.momentFilters.moment_tags.findIndex(x => x === tag);
      // Remove or add the moment tag based on the index
      (momentTagIndex >= 0) ? this.momentFilters.moment_tags.splice(momentTagIndex, 1) : this.momentFilters.moment_tags.push(tag);      
      // Get the Moment templates
      this.getMomentTemplates(false);
    },
    toggleMomentTemplateTags() {
      (this.showAllMomentTemplateTags === false) ? this.showAllMomentTemplateTags = true : this.showAllMomentTemplateTags = false;
    },
    setMomentTemplateLanguages() {
      if(this.momentTemplateLanguagesLoaded === false) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/moment-templates/languages')
        .then(res => {
          this.momentTemplateLanguages = res.data.data;
          // Update the momentTemplateLanguagesLoaded value
          this.momentTemplateLanguagesLoaded = true;        
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Create array for momentTemplateLanguages
        let momentTemplateLanguages = [];
        // Loop through the Moment templates
        for(var g = 0; g < this.moments.length; g++) {
          momentTemplateLanguages = momentTemplateLanguages.concat(this.moments[g].loyalty_moment_languages);
        }
        // Create momentTemplateLanguagesCount array with counts of remaining languages
        var momentTemplateLanguagesCount = momentTemplateLanguages.reduce((languageCount, language) => {
          if(languageCount.hasOwnProperty(language) === false) languageCount[language] = 0;
          languageCount[language]++;
          return languageCount;
        }, {});
        // Update the momentTemplateLanguages array
        this.momentTemplateLanguages = Object.keys(momentTemplateLanguagesCount).map(language => {
          return {language_name: language, moments_with_language: momentTemplateLanguagesCount[language]}; 
        });
        // Sort the momentTemplateLanguages
        this.momentTemplateLanguages.sort((a,b)=> (b.moments_with_language - a.moments_with_language || a.language_name.localeCompare(b.language_name))); 
      }
    },    
    setSelectedMomentTemplateLanguages(language) {
      // Get the index of the Moment template language in the moment_languages array of the momentFilters
      let momentLanguageIndex = this.momentFilters.moment_languages.findIndex(x => x === language);
      // Remove or add the moment language based on the index
      (momentLanguageIndex >= 0) ? this.momentFilters.moment_languages.splice(momentLanguageIndex, 1) : this.momentFilters.moment_languages.push(language);      
      // Get the Moment templates
      this.getMomentTemplates(false);
    },
    toggleMomentTemplateLanguages() {
      (this.showAllMomentTemplateLanguages === false) ? this.showAllMomentTemplateLanguages = true : this.showAllMomentTemplateLanguages = false;
    },    
    showMomentTemplateEditPage(id) {
      this.$router.push({path: `/loyalty/moments/template/${id.toString()}`});
    },       
    showMomentSendPage(id) {
      this.$router.push({path: `/loyalty/moments/template/${id.toString()}/send`});
    },
    getDefaultCompanyLanguage() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company/language/default')
      .then(res => {      
        this.defaultCompanyLanguage = res.data.data;  
        // Check if the default company language is available
        if(this.defaultCompanyLanguage && this.defaultCompanyLanguage.name) {
          // Set the default moment language filter
          this.momentFilters.moment_languages.push(this.defaultCompanyLanguage.name);
          this.momentTemplateLanguagesLoaded = true;
          this.momentTemplateTagsLoaded = true;
        }
        // Get the moment templates
        this.getMomentTemplates(true);
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
        // Update the platformRolesLoaded value
        this.platformRolesLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    }         
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');    

    this.checkModules();
    this.getPlatformRoles();
    this.getPlatformPermissions();
    this.getDefaultCompanyLanguage();    

    this.$bus.$on('loyalty_moment_updated', (e) => {
      this.getMomentTemplates(false);
    });

    this.$bus.$on('loyalty_moment_added', (e) => {      
      this.getMomentTemplates(false);
    });

    this.$bus.$on('custom_loyalty_moment_added', (e) => {      
      this.getMomentTemplates(false);
    });
  },
  beforeDestroy() {
    this.$bus.$off('loyalty_moment_updated');
    this.$bus.$off('loyalty_moment_added');
    this.$bus.$off('custom_loyalty_moment_added');
  }
}
</script>
